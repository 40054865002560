import type { IconRootProps } from './Root'
import { IconRoot } from './Root'

export const AppleIcon = ({ size = '1.5rem', ...props }: IconRootProps) => (
  <IconRoot
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    {...props}
  >
    <g clipPath="url(#clip0_4644_1080)">
      <path
        d="M15.8825 0.613281C15.9355 0.613281 15.9885 0.613281 16.0445 0.613281C16.1745 2.21928 15.5615 3.41928 14.8165 4.28828C14.0855 5.15128 13.0845 5.98828 11.4655 5.86128C11.3575 4.27828 11.9715 3.16728 12.7155 2.30028C13.4055 1.49228 14.6705 0.773281 15.8825 0.613281Z"
        fill="#121212"
      />
      <path
        d="M20.7833 17.3295C20.7833 17.3455 20.7833 17.3595 20.7833 17.3745C20.3283 18.7525 19.6793 19.9335 18.8873 21.0295C18.1643 22.0245 17.2783 23.3635 15.6963 23.3635C14.3293 23.3635 13.4213 22.4845 12.0203 22.4605C10.5383 22.4365 9.72329 23.1955 8.36829 23.3865C8.21329 23.3865 8.05829 23.3865 7.90629 23.3865C6.91129 23.2425 6.10828 22.4545 5.52328 21.7445C3.79828 19.6465 2.46529 16.9365 2.21729 13.4685C2.21729 13.1285 2.21729 12.7895 2.21729 12.4495C2.32229 9.96748 3.52829 7.94948 5.13129 6.97148C5.97729 6.45148 7.14028 6.00848 8.43528 6.20648C8.99028 6.29248 9.55729 6.48248 10.0543 6.67048C10.5253 6.85148 11.1143 7.17248 11.6723 7.15548C12.0503 7.14448 12.4263 6.94748 12.8073 6.80848C13.9233 6.40548 15.0173 5.94348 16.4593 6.16048C18.1923 6.42248 19.4223 7.19248 20.1823 8.38048C18.7163 9.31348 17.5573 10.7195 17.7553 13.1205C17.9313 15.3015 19.1993 16.5775 20.7833 17.3295Z"
        fill="#121212"
      />
    </g>
    <defs>
      <clipPath id="clip0_4644_1080">
        <rect
          width="22.773"
          height="22.773"
          fill="white"
          transform="translate(0.113281 0.613281)"
        />
      </clipPath>
    </defs>
  </IconRoot>
)
