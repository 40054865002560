import type { IconRootProps } from './Root'
import { IconRoot } from './Root'

export const AndroidIcon = ({ size = '1.5rem', ...props }: IconRootProps) => (
  <IconRoot
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    {...props}
  >
    <g clipPath="url(#clip0_4644_1022)">
      <path
        d="M8.29907 14.4648C9.08236 14.4648 9.71737 15.0998 9.71737 15.8831V21.9673C9.71737 22.7506 9.08236 23.3855 8.29907 23.3855C7.51587 23.3855 6.88086 22.7506 6.88086 21.9673V15.8831C6.88086 15.0999 7.51587 14.4649 8.29907 14.4649V14.4648Z"
        fill="#121212"
      />
      <path
        d="M5.06345 7.15039C5.05877 7.20417 5.05469 7.25855 5.05469 7.31352V16.5316C5.05469 17.5229 5.83938 18.3215 6.8135 18.3215H16.1876C17.1618 18.3215 17.9465 17.5228 17.9465 16.5316V7.31352C17.9465 7.25855 17.9445 7.20397 17.9399 7.15039H5.06345Z"
        fill="#121212"
      />
      <path
        d="M16.1181 15.8835C16.1181 15.1002 15.4831 14.4652 14.6998 14.4652C13.9166 14.4652 13.2816 15.1003 13.2816 15.8835V21.9676C13.2816 22.7509 13.9166 23.3858 14.6998 23.3858C15.4831 23.3858 16.1181 22.7509 16.1181 21.9676V15.8835Z"
        fill="#121212"
      />
      <path
        d="M4.19393 9.38901C4.19393 8.60571 3.55893 7.9707 2.77573 7.9707C1.99233 7.9707 1.35732 8.60581 1.35742 9.38911V15.4732C1.35742 16.2564 1.99243 16.8915 2.77573 16.8915C3.55893 16.8915 4.19393 16.2564 4.19393 15.4731V9.38901Z"
        fill="#121212"
      />
      <path
        d="M21.6435 9.38901C21.6435 8.60571 21.0085 7.9707 20.2253 7.9707C19.442 7.9707 18.807 8.60581 18.807 9.38911V15.4732C18.807 16.2564 19.442 16.8915 20.2253 16.8915C21.0085 16.8915 21.6435 16.2564 21.6435 15.4731V9.38901Z"
        fill="#121212"
      />
      <path
        d="M5.59766 0.8125L7.09374 3.40391M17.4027 0.8125L15.9065 3.40391"
        stroke="#121212"
        strokeWidth="0.298779"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7884 1.08594C7.61621 1.39189 5.14491 3.7134 5.10547 6.54483H17.8968C17.8574 3.7136 15.3863 1.39199 12.2138 1.08594H10.7884ZM8.86275 4.72639C9.26809 4.7253 9.59565 4.40351 9.59446 4.00773V4.00374C9.59326 3.60776 9.26381 3.28797 8.85867 3.28907C8.45342 3.29016 8.12586 3.61185 8.12696 4.00773V4.01171C8.12815 4.4075 8.4576 4.72739 8.86275 4.72639ZM14.34 4.72639C14.7453 4.7253 15.0729 4.40351 15.0717 4.00773V4.00374C15.0705 3.60776 14.741 3.28797 14.3359 3.28907C13.9306 3.29016 13.6031 3.61185 13.6042 4.00773V4.01171C13.6054 4.4075 13.9348 4.72739 14.34 4.72639Z"
        fill="#121212"
      />
    </g>
    <defs>
      <clipPath id="clip0_4644_1022">
        <rect
          width="22.773"
          height="22.773"
          fill="white"
          transform="translate(0.113281 0.613281)"
        />
      </clipPath>
    </defs>
  </IconRoot>
)
